import { NgModule } from '@angular/core';
import { CommonModule, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { TableSearchHeaderModule } from '@fc-shared/ui/tables/table-search-header/table-search-header.module';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { ImageLoaderModule } from '@fc-shared/ui/image-loader/image-loader.module';
import { MatSortModule } from '@angular/material/sort';
import { VehicleGroupColorsModule } from '@fc-vehicles/components/vehicle-group-colors/vehicle-group-colors.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TableCardModule } from '@fc-shared/ui/table-card/table-card.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EmptyStateModule } from '@fc-shared/ui/empty-state/empty-state.module';
import { SkeletonModule } from '@fc-shared/ui/skeleton/skeleton.module';
import { VehicleTableComponent } from '@fc-shared/ui/tables/vehicle-table/vehicle-table.component';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';

@NgModule({
  declarations: [VehicleTableComponent],
  exports: [VehicleTableComponent],
  imports: [
    CommonModule,
    TableSearchHeaderModule,
    MatTableModule,
    RouterLink,
    ImageLoaderModule,
    MatSortModule,
    VehicleGroupColorsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    NgTemplateOutlet,
    TableCardModule,
    SlicePipe,
    MatPaginatorModule,
    EmptyStateModule,
    SkeletonModule,
    IconButtonComponent,
    IconComponent,
  ],
})
export class VehicleTableModule {}
